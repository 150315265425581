
export default {
  name: 'PriceField',

  props: {
    label: {
      type: String,
      default: '',
    },

    price: {
      type: Object,
      required: true,
    },

    from: {
      type: Boolean,
      default: false,
    },

    logo: {
      type: Boolean,
      default: false,
    },

    sponsored: {
      type: Boolean,
      default: false,
    },

    isLogoPin: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    showFrom () {
      if ('preposition' in (this.price || {})) {
        return this.price.preposition
      }

      return this.from
    },
  },

  methods: {
    formatPrice () {
      const currency = ['USD', 'PLN', 'RON', 'EUR'].includes(this.price?.currency) ? this.price?.currency : 'PLN'

      return new Intl
        .NumberFormat(this.$i18n.locale, { style: 'currency', currency })
        .format(this.price?.value)
    },
  },
}
