import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _99c50e34 = () => interopDefault(import('../pages/ac.vue' /* webpackChunkName: "pages/ac" */))
const _61f39b76 = () => interopDefault(import('../pages/ac/_.vue' /* webpackChunkName: "pages/ac/_" */))
const _ef5489ec = () => interopDefault(import('../node_modules/@strix/checkout/pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _f2af5be6 = () => interopDefault(import('../node_modules/@strix/checkout/pages/checkout/index/index.vue' /* webpackChunkName: "pages/checkout/index/index" */))
const _83676786 = () => interopDefault(import('../node_modules/@strix/checkout/pages/checkout/index/auth.vue' /* webpackChunkName: "pages/checkout/index/auth" */))
const _046cd221 = () => interopDefault(import('../node_modules/@strix/checkout/pages/checkout/index/step2.vue' /* webpackChunkName: "pages/checkout/index/step2" */))
const _047ae9a2 = () => interopDefault(import('../node_modules/@strix/checkout/pages/checkout/index/step3.vue' /* webpackChunkName: "pages/checkout/index/step3" */))
const _854ec4bc = () => interopDefault(import('../pages/customer.vue' /* webpackChunkName: "pages/customer" */))
const _1b2ce2a5 = () => interopDefault(import('../pages/customer/index.vue' /* webpackChunkName: "pages/customer/index" */))
const _89085b34 = () => interopDefault(import('../pages/customer/agreements.vue' /* webpackChunkName: "pages/customer/agreements" */))
const _5fca1096 = () => interopDefault(import('../pages/customer/beneficiaries.vue' /* webpackChunkName: "pages/customer/beneficiaries" */))
const _a16a1be6 = () => interopDefault(import('../pages/customer/card.vue' /* webpackChunkName: "pages/customer/card" */))
const _0f45d807 = () => interopDefault(import('../pages/customer/edit.vue' /* webpackChunkName: "pages/customer/edit" */))
const _249171b6 = () => interopDefault(import('../pages/customer/orders/index.vue' /* webpackChunkName: "pages/customer/orders/index" */))
const _415781c7 = () => interopDefault(import('../pages/customer/questionnaire/mental-health.vue' /* webpackChunkName: "pages/customer/questionnaire/mental-health" */))
const _3bad286e = () => interopDefault(import('../pages/customer/questionnaire/participants.vue' /* webpackChunkName: "pages/customer/questionnaire/participants" */))
const _97726266 = () => interopDefault(import('../pages/customer/orders/_id.vue' /* webpackChunkName: "pages/customer/orders/_id" */))
const _11acb084 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _62d8ce5a = () => interopDefault(import('../pages/invoice-data-form.vue' /* webpackChunkName: "pages/invoice-data-form" */))
const _13b73d97 = () => interopDefault(import('../pages/mediclub.vue' /* webpackChunkName: "pages/mediclub" */))
const _72e84876 = () => interopDefault(import('../pages/modal-test.vue' /* webpackChunkName: "pages/modal-test" */))
const _542e0fa4 = () => interopDefault(import('../pages/rate.vue' /* webpackChunkName: "pages/rate" */))
const _b399c5d2 = () => interopDefault(import('../pages/complaint.vue' /* webpackChunkName: "pages/complaint" */))
const _783b23e4 = () => interopDefault(import('../pages/terms-form.vue' /* webpackChunkName: "pages/terms-form" */))
const _05e4daa8 = () => interopDefault(import('../pages/thank-you.vue' /* webpackChunkName: "pages/thank-you" */))
const _a98ce030 = () => interopDefault(import('../pages/update-beneficiary/index.vue' /* webpackChunkName: "pages/update-beneficiary/index" */))
const _6ab442af = () => interopDefault(import('../pages/returns.vue' /* webpackChunkName: "pages/returns" */))
const _cd6db5f4 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _4626f786 = () => interopDefault(import('../pages/auth/logout.vue' /* webpackChunkName: "pages/auth/logout" */))
const _1f977dff = () => interopDefault(import('../pages/auth/password-change.vue' /* webpackChunkName: "pages/auth/password-change" */))
const _64e82dd0 = () => interopDefault(import('../pages/auth/password-reset.vue' /* webpackChunkName: "pages/auth/password-reset" */))
const _7586e3d4 = () => interopDefault(import('../pages/auth/register.vue' /* webpackChunkName: "pages/auth/register" */))
const _0379a1f7 = () => interopDefault(import('../pages/c/null.vue' /* webpackChunkName: "pages/c/null" */))
const _191a62a5 = () => interopDefault(import('../node_modules/@strix/checkout/pages/checkout/EmptyCart.vue' /* webpackChunkName: "pages/checkout/EmptyCart" */))
const _6b34608a = () => interopDefault(import('../pages/p/null.vue' /* webpackChunkName: "pages/p/null" */))
const _5d6191e9 = () => interopDefault(import('../node_modules/@strix/search/pages/search/q.vue' /* webpackChunkName: "pages/search/q" */))
const _3bdec45c = () => interopDefault(import('../pages/update-beneficiary/thank-you.vue' /* webpackChunkName: "pages/update-beneficiary/thank-you" */))
const _e41e0ad2 = () => interopDefault(import('../pages/p/_slug.vue' /* webpackChunkName: "pages/p/_slug" */))
const _e6bf151e = () => interopDefault(import('~/pages/p/_slug.vue' /* webpackChunkName: "" */))
const _5a676e9d = () => interopDefault(import('../pages/a/_.vue' /* webpackChunkName: "pages/a/_" */))
const _779835c2 = () => interopDefault(import('../pages/c/_.vue' /* webpackChunkName: "pages/c/_" */))
const _26648a6a = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/ac",
    component: _99c50e34,
    pathToRegexpOptions: {"strict":true},
    name: "ac___pl",
    children: [{
      path: "*",
      component: _61f39b76,
      pathToRegexpOptions: {"strict":true},
      name: "ac-all___pl"
    }]
  }, {
    path: "/checkout",
    component: _ef5489ec,
    children: [{
      path: "",
      component: _f2af5be6,
      name: "checkout-index___pl"
    }, {
      path: "auth",
      component: _83676786,
      name: "checkout-index-auth___pl"
    }, {
      path: "step2",
      component: _046cd221,
      name: "checkout-index-step2___pl"
    }, {
      path: "step3",
      component: _047ae9a2,
      name: "checkout-index-step3___pl"
    }]
  }, {
    path: "/customer",
    component: _854ec4bc,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "",
      component: _1b2ce2a5,
      pathToRegexpOptions: {"strict":true},
      name: "customer___pl"
    }, {
      path: "agreements",
      component: _89085b34,
      pathToRegexpOptions: {"strict":true},
      name: "customer-agreements___pl"
    }, {
      path: "beneficiaries",
      component: _5fca1096,
      pathToRegexpOptions: {"strict":true},
      name: "customer-beneficiaries___pl"
    }, {
      path: "card",
      component: _a16a1be6,
      pathToRegexpOptions: {"strict":true},
      name: "customer-card___pl"
    }, {
      path: "edit",
      component: _0f45d807,
      pathToRegexpOptions: {"strict":true},
      name: "customer-edit___pl"
    }, {
      path: "orders",
      component: _249171b6,
      pathToRegexpOptions: {"strict":true},
      name: "customer-orders___pl"
    }, {
      path: "questionnaire/mental-health",
      component: _415781c7,
      pathToRegexpOptions: {"strict":true},
      name: "customer-questionnaire-mental-health___pl"
    }, {
      path: "questionnaire/participants",
      component: _3bad286e,
      pathToRegexpOptions: {"strict":true},
      name: "customer-questionnaire-participants___pl"
    }, {
      path: "orders/:id",
      component: _97726266,
      pathToRegexpOptions: {"strict":true},
      name: "customer-orders-id___pl"
    }]
  }, {
    path: "/en",
    component: _11acb084,
    pathToRegexpOptions: {"strict":true},
    name: "index___en"
  }, {
    path: "/formularz-danych-do-faktury",
    component: _62d8ce5a,
    pathToRegexpOptions: {"strict":true},
    name: "invoice-data-form___pl"
  }, {
    path: "/mediclub",
    component: _13b73d97,
    pathToRegexpOptions: {"strict":true},
    name: "mediclub___pl"
  }, {
    path: "/modal-test",
    component: _72e84876,
    pathToRegexpOptions: {"strict":true},
    name: "modal-test___pl"
  }, {
    path: "/opinie",
    component: _542e0fa4,
    pathToRegexpOptions: {"strict":true},
    name: "rate___pl"
  }, {
    path: "/reklamacja",
    component: _b399c5d2,
    pathToRegexpOptions: {"strict":true},
    name: "complaint___pl"
  }, {
    path: "/terms-form",
    component: _783b23e4,
    pathToRegexpOptions: {"strict":true},
    name: "terms-form___pl"
  }, {
    path: "/thank-you",
    component: _05e4daa8,
    pathToRegexpOptions: {"strict":true},
    name: "thank-you___pl"
  }, {
    path: "/update-beneficiary",
    component: _a98ce030,
    pathToRegexpOptions: {"strict":true},
    name: "update-beneficiary___pl"
  }, {
    path: "/zwroty",
    component: _6ab442af,
    pathToRegexpOptions: {"strict":true},
    name: "returns___pl"
  }, {
    path: "/auth/login",
    component: _cd6db5f4,
    pathToRegexpOptions: {"strict":true},
    name: "auth-login___pl"
  }, {
    path: "/auth/logout",
    component: _4626f786,
    pathToRegexpOptions: {"strict":true},
    name: "auth-logout___pl"
  }, {
    path: "/auth/password-change",
    component: _1f977dff,
    pathToRegexpOptions: {"strict":true},
    name: "auth-password-change___pl"
  }, {
    path: "/auth/password-reset",
    component: _64e82dd0,
    pathToRegexpOptions: {"strict":true},
    name: "auth-password-reset___pl"
  }, {
    path: "/auth/register",
    component: _7586e3d4,
    pathToRegexpOptions: {"strict":true},
    name: "auth-register___pl"
  }, {
    path: "/c/null",
    component: _0379a1f7,
    pathToRegexpOptions: {"strict":true},
    name: "c-null___pl"
  }, {
    path: "/checkout/EmptyCart",
    component: _191a62a5,
    name: "checkout-EmptyCart___pl"
  }, {
    path: "/en/ac",
    component: _99c50e34,
    pathToRegexpOptions: {"strict":true},
    name: "ac___en",
    children: [{
      path: "*",
      component: _61f39b76,
      pathToRegexpOptions: {"strict":true},
      name: "ac-all___en"
    }]
  }, {
    path: "/en/checkout",
    component: _ef5489ec,
    children: [{
      path: "",
      component: _f2af5be6,
      name: "checkout-index___en"
    }, {
      path: "auth",
      component: _83676786,
      name: "checkout-index-auth___en"
    }, {
      path: "step2",
      component: _046cd221,
      name: "checkout-index-step2___en"
    }, {
      path: "step3",
      component: _047ae9a2,
      name: "checkout-index-step3___en"
    }]
  }, {
    path: "/en/complaints",
    component: _b399c5d2,
    pathToRegexpOptions: {"strict":true},
    name: "complaint___en"
  }, {
    path: "/en/customer",
    component: _854ec4bc,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "",
      component: _1b2ce2a5,
      pathToRegexpOptions: {"strict":true},
      name: "customer___en"
    }, {
      path: "agreements",
      component: _89085b34,
      pathToRegexpOptions: {"strict":true},
      name: "customer-agreements___en"
    }, {
      path: "beneficiaries",
      component: _5fca1096,
      pathToRegexpOptions: {"strict":true},
      name: "customer-beneficiaries___en"
    }, {
      path: "card",
      component: _a16a1be6,
      pathToRegexpOptions: {"strict":true},
      name: "customer-card___en"
    }, {
      path: "edit",
      component: _0f45d807,
      pathToRegexpOptions: {"strict":true},
      name: "customer-edit___en"
    }, {
      path: "orders",
      component: _249171b6,
      pathToRegexpOptions: {"strict":true},
      name: "customer-orders___en"
    }, {
      path: "questionnaire/mental-health",
      component: _415781c7,
      pathToRegexpOptions: {"strict":true},
      name: "customer-questionnaire-mental-health___en"
    }, {
      path: "questionnaire/participants",
      component: _3bad286e,
      pathToRegexpOptions: {"strict":true},
      name: "customer-questionnaire-participants___en"
    }, {
      path: "orders/:id",
      component: _97726266,
      pathToRegexpOptions: {"strict":true},
      name: "customer-orders-id___en"
    }]
  }, {
    path: "/en/invoice-data-form",
    component: _62d8ce5a,
    pathToRegexpOptions: {"strict":true},
    name: "invoice-data-form___en"
  }, {
    path: "/en/mediclub",
    component: _13b73d97,
    pathToRegexpOptions: {"strict":true},
    name: "mediclub___en"
  }, {
    path: "/en/modal-test",
    component: _72e84876,
    pathToRegexpOptions: {"strict":true},
    name: "modal-test___en"
  }, {
    path: "/en/rate",
    component: _542e0fa4,
    pathToRegexpOptions: {"strict":true},
    name: "rate___en"
  }, {
    path: "/en/returns",
    component: _6ab442af,
    pathToRegexpOptions: {"strict":true},
    name: "returns___en"
  }, {
    path: "/en/terms-form",
    component: _783b23e4,
    pathToRegexpOptions: {"strict":true},
    name: "terms-form___en"
  }, {
    path: "/en/thank-you",
    component: _05e4daa8,
    pathToRegexpOptions: {"strict":true},
    name: "thank-you___en"
  }, {
    path: "/en/update-beneficiary",
    component: _a98ce030,
    pathToRegexpOptions: {"strict":true},
    name: "update-beneficiary___en"
  }, {
    path: "/p/null",
    component: _6b34608a,
    pathToRegexpOptions: {"strict":true},
    name: "p-null___pl"
  }, {
    path: "/search/q",
    component: _5d6191e9,
    name: "search-q___pl"
  }, {
    path: "/update-beneficiary/thank-you",
    component: _3bdec45c,
    pathToRegexpOptions: {"strict":true},
    name: "update-beneficiary-thank-you___pl"
  }, {
    path: "/en/auth/login",
    component: _cd6db5f4,
    pathToRegexpOptions: {"strict":true},
    name: "auth-login___en"
  }, {
    path: "/en/auth/logout",
    component: _4626f786,
    pathToRegexpOptions: {"strict":true},
    name: "auth-logout___en"
  }, {
    path: "/en/auth/password-change",
    component: _1f977dff,
    pathToRegexpOptions: {"strict":true},
    name: "auth-password-change___en"
  }, {
    path: "/en/auth/password-reset",
    component: _64e82dd0,
    pathToRegexpOptions: {"strict":true},
    name: "auth-password-reset___en"
  }, {
    path: "/en/auth/register",
    component: _7586e3d4,
    pathToRegexpOptions: {"strict":true},
    name: "auth-register___en"
  }, {
    path: "/en/c/null",
    component: _0379a1f7,
    pathToRegexpOptions: {"strict":true},
    name: "c-null___en"
  }, {
    path: "/en/checkout/EmptyCart",
    component: _191a62a5,
    name: "checkout-EmptyCart___en"
  }, {
    path: "/en/p/null",
    component: _6b34608a,
    pathToRegexpOptions: {"strict":true},
    name: "p-null___en"
  }, {
    path: "/en/search/q",
    component: _5d6191e9,
    name: "search-q___en"
  }, {
    path: "/en/update-beneficiary/thank-you",
    component: _3bdec45c,
    pathToRegexpOptions: {"strict":true},
    name: "update-beneficiary-thank-you___en"
  }, {
    path: "/en/p/:slug?",
    component: _e41e0ad2,
    pathToRegexpOptions: {"strict":true},
    name: "p-slug___en"
  }, {
    path: "/en/p/:slug/:city",
    component: _e6bf151e,
    name: "p-slug-city___en"
  }, {
    path: "/en/a/*",
    component: _5a676e9d,
    pathToRegexpOptions: {"strict":true},
    name: "a-all___en"
  }, {
    path: "/en/c/*",
    component: _779835c2,
    pathToRegexpOptions: {"strict":true},
    name: "c-all___en"
  }, {
    path: "/p/:slug?",
    component: _e41e0ad2,
    pathToRegexpOptions: {"strict":true},
    name: "p-slug___pl"
  }, {
    path: "/p/:slug/:city",
    component: _e6bf151e,
    name: "p-slug-city___pl"
  }, {
    path: "/en/*",
    component: _26648a6a,
    name: "all1___en"
  }, {
    path: "/en/*",
    component: _26648a6a,
    pathToRegexpOptions: {"strict":true},
    name: "all___en"
  }, {
    path: "/a/*",
    component: _5a676e9d,
    pathToRegexpOptions: {"strict":true},
    name: "a-all___pl"
  }, {
    path: "/c/*",
    component: _779835c2,
    pathToRegexpOptions: {"strict":true},
    name: "c-all___pl"
  }, {
    path: "/",
    component: _11acb084,
    pathToRegexpOptions: {"strict":true},
    name: "index___pl"
  }, {
    path: "/*",
    component: _26648a6a,
    name: "all1___pl"
  }, {
    path: "/*",
    component: _26648a6a,
    pathToRegexpOptions: {"strict":true},
    name: "all___pl"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
