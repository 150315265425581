
export default {
  name: 'MediClubLogo',
  props: {
    oneline: {
      type: Boolean,
      default: false,
    },

    white: {
      type: Boolean,
      default: false,
    },

    widerLogo: {
      type: Boolean,
      default: false,
    },

    widthOneline: {
      type: Number,
      default: 99,
    },

    heightOneline: {
      type: Number,
      default: 19,
    },
  },

  computed: {
    getColor () {
      return this.white ? require('~/assets/sprite/mediclub/mediclub-logo-oneline-white.svg') : require('~/assets/sprite/mediclub/mediclub-logo-oneline.svg')
    },
  },
}
