import { cartItemTelemedicine } from '@strix/checkout/helpers/cartItemTelemedicine'
import { Schema } from '@/config/forms/beneficiariesProfile'
import { isValueSet } from '@/utils/Utils'

const Fields = {
  name: ['firstname', 'lastname'],
  dob: ['dateOfBirth'],
  peselGender: ['govId', 'passport', 'govIdGuardian', 'passportMother', 'gender'],
  emailPhone: ['email', 'phone'],
  email: ['email'],
  billingAddress: ['addressStreet', 'addressHouseNumber', 'addressCity', 'addressPostcode'],
  deliveryAddress: ['mailingStreet', 'mailingHouseNumber', 'mailingCity', 'mailingPostcode'],
}

const Type = {
  AppointmentProduct: 'AppointmentProduct',
  AppointmentWithDoctorProduct: 'AppointmentWithDoctorProduct',
  MedicalCheckProduct: 'MedicalCheckProduct',
  MedicalPackageProduct: 'MedicalPackageProduct',
  OneTimeServiceProduct: 'OneTimeServiceProduct',
  PackageOfServicesProduct: 'PackageOfServicesProduct',
  PackagedServiceProduct: 'PackagedServiceProduct',
  PhysicalProduct: 'PhysicalProduct',
  PrepaidCardProduct: 'PrepaidCardProduct',
  SimpleService: 'SimpleService',
  SimpleServiceProduct: 'SimpleServiceProduct',
}

const Vendor = {
  Synevo: 'Synevo',
  Rehasport: 'Rehasport',
  RankingLekarzy: 'Ranking Lekarzy',
  Diagnostyka: 'Diagnostyka',
  OSOZ: ['Centrum Medyczne MML',
    'Dom Lekarski',
    'Nasz Lekarz'],
}

const Purchaser = {
  myself: 'myself',
  other: 'other',
}

const rules = [
  {
    conditions: {
      purchaser: [
        Purchaser.myself,
      ],
      type: [
        Type.AppointmentProduct,
        Type.AppointmentWithDoctorProduct,
        Type.PackageOfServicesProduct,
        Type.PackagedServiceProduct,
        Type.OneTimeServiceProduct,
      ],
      telemedicine: [
        false,
      ],
    },
    fields: [
      ...Fields.name,
      ...Fields.dob,
      ...Fields.emailPhone,
      ...Fields.billingAddress,
    ],
  },
  {
    conditions: {
      purchaser: [
        Purchaser.myself,
        Purchaser.other,
      ],
      type: [
        Type.MedicalPackageProduct,
        Type.MedicalCheckProduct,
        Type.PrepaidCardProduct,
        Type.SimpleService,
        Type.SimpleServiceProduct,
      ],
      telemedicine: [
        true,
        false,
      ],
    },
    fields: [
      ...Fields.name,
      ...Fields.dob,
      ...Fields.peselGender,
      ...Fields.emailPhone,
      ...Fields.billingAddress,
    ],
  },
  {
    conditions: {
      purchaser: [
        Purchaser.myself,
        Purchaser.other,
      ],
      type: [
        Type.PhysicalProduct,
      ],
      telemedicine: [
        true,
        false,
      ],
    },
    fields: [
      ...Fields.name,
      ...Fields.dob,
      ...Fields.peselGender,
      ...Fields.emailPhone,
      ...Fields.billingAddress,
      ...Fields.deliveryAddress,
    ],
  },
  {
    conditions: {
      purchaser: [
        Purchaser.myself,
        Purchaser.other,
      ],
      type: [
        Type.AppointmentProduct,
        Type.AppointmentWithDoctorProduct,
      ],
      telemedicine: [
        true,
      ],
    },
    fields: [
      ...Fields.name,
      ...Fields.dob,
      ...Fields.peselGender,
      ...Fields.emailPhone,
      ...Fields.billingAddress,
    ],
  },
  {
    conditions: {
      purchaser: [
        Purchaser.myself,
        Purchaser.other,
      ],
      type: [
        Type.AppointmentProduct,
        Type.AppointmentWithDoctorProduct,
      ],
      vendor: [
        Vendor.RankingLekarzy,
        Vendor.Rehasport,
        Vendor.Synevo,
      ],
      telemedicine: [
        false,
      ],
    },
    fields: [
      ...Fields.name,
      ...Fields.dob,
      ...Fields.peselGender,
      ...Fields.emailPhone,
      ...Fields.billingAddress,
    ],
  },
  {
    conditions: {
      purchaser: [
        Purchaser.myself,
        Purchaser.other,
      ],
      type: [
        Type.AppointmentProduct,
        Type.AppointmentWithDoctorProduct,
      ],
      vendor: [
        Vendor.RankingLekarzy,
        Vendor.Rehasport,
        Vendor.Synevo,
        ...Vendor.OSOZ,
      ],
      telemedicine: [
        true,
        false,
      ],
    },
    fields: [
      ...Fields.name,
      ...Fields.dob,
      ...Fields.peselGender,
      ...Fields.emailPhone,
      ...Fields.billingAddress,
    ],
  },
  {
    conditions: {
      purchaser: [
        Purchaser.myself,
        Purchaser.other,
      ],
      type: [
        Type.OneTimeServiceProduct,
        Type.PackageOfServicesProduct,
      ],
      telemedicine: [
        false,
      ],
      vendor: [
        Vendor.Synevo,
      ],
    },
    fields: [
      ...Fields.name,
      ...Fields.dob,
      ...Fields.peselGender,
      ...Fields.emailPhone,
      ...Fields.billingAddress,
    ],
  },
  {
    conditions: {
      purchaser: [
        Purchaser.other,
      ],
      type: [
        Type.AppointmentProduct,
        Type.AppointmentWithDoctorProduct,
        Type.OneTimeServiceProduct,
        Type.PackageOfServicesProduct,
        Type.PackagedServiceProduct,
      ],
      telemedicine: [
        false,
      ],
    },
    fields: [
      ...Fields.name,
      ...Fields.dob,
    ],
  },
  {
    conditions: {
      purchaser: [
        Purchaser.myself,
        Purchaser.other,
      ],
      type: [
        Type.OneTimeServiceProduct,
        Type.PackageOfServicesProduct,
        Type.PackagedServiceProduct,
      ],
      telemedicine: [
        false,
        true,
      ],
      vendor: [
        Vendor.Diagnostyka,
      ],
    },
    fields: [
      ...Fields.name,
      ...Fields.peselGender,
      ...Fields.email,
      ...Fields.billingAddress,
    ],
  },
]

const getAllElements = (schema) => {
  return Object.values(schema.sections).reduce((acc, { elements }) => ({ ...acc, ...elements }), {})
}

export const getRequiredFieldsForCartItem = (item, purchaser = Purchaser.myself) => {
  const { vendor: { name: vendor }, product: { _typename: type } } = item
  const telemedicine = cartItemTelemedicine(item)

  const conditions = {
    purchaser,
    type,
    telemedicine,
    vendor,
  }

  let ruleFound
  const ruleFindCallback = rule => Object.entries(conditions).every(([key, val]) => rule.conditions[key]?.includes(val))

  ruleFound = rules.find(ruleFindCallback)

  if (!ruleFound) {
    delete conditions.vendor

    ruleFound = rules.filter(rule => !('vendor' in rule.conditions)).find(ruleFindCallback)
  }

  return ruleFound?.fields || []
}

export const getSchemaForCartItem = (item, purchaser = Purchaser.myself) => {
  const fields = getRequiredFieldsForCartItem(item, purchaser)
  const schema = JSON.parse(JSON.stringify(Schema))

  if (fields.length) {
    const elements = getAllElements(schema)

    Object.entries(elements).forEach(([name, element]) => {
      if (element.rules) {
        element.rules = element.rules?.split('|').filter(r => r !== 'required').filter(Boolean).join('|')
      }

      if (fields.includes(name)) {
        element.rules = (element.rules?.split('|') || []).concat(['required']).filter(Boolean).join('|')
      }
    })
  }

  return schema
}

export const isBeneficiaryFilledForCartItem = (model = {}, item, purchaser) => {
  const fields = getRequiredFieldsForCartItem(item, purchaser)

  const isGovIdRequired = fields.includes('govId') && !model.isChild && !model.isForeigner
  const isPassportRequired = fields.includes('passport') && !model.isChild && model.isForeigner
  const isGovIdGuardianRequired = fields.includes('govIdGuardian') && model.isChild && !model.isForeigner
  const isPassportMotherRequired = fields.includes('passportMother') && model.isChild && model.isForeigner

  if (
    (isGovIdRequired && !model.govId) ||
    (isPassportRequired && !model.passport) ||
    (isGovIdGuardianRequired && !model.govIdGuardian) ||
    (isPassportMotherRequired && !model.passportMother)
  ) {
    return false
  }

  return fields.every(field => isValueSet(model[field]) || ['govId', 'passport', 'govIdGuardian', 'passportMother'].includes(field))
}
