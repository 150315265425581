
import { createNamespacedHelpers } from 'vuex'

const {
  mapState: mapStateUser,
} = createNamespacedHelpers('user')

export default {
  name: 'HeaderButtonAccount',

  data () {
    return {
      isOpen: false,
    }
  },

  computed: {
    ...mapStateUser({
      isLogged: state => state.isLogged,
      profile: state => state.profile,
    }),

    uri () {
      return this.isLogged ? 'customer-orders' : 'auth-login'
    },

    accountBtnText () {
      return this.isLogged ? `${this.profile.firstname} ${this.profile.lastname}` : this.$t('Account')
    },
  },

  methods: {
    openMenu () {
      this.isOpen = true
    },

    closeMenu () {
      this.isOpen = false
    },
  },
}
