
export default {
  name: 'BaseDialog',

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    closeOnBackdrop: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      isOpen: this.value,
      scale: false,
    }
  },

  watch: {
    value: {
      handler (value) {
        this.isOpen = value
      },

      immediate: true,
    },
  },

  methods: {
    open () {
      this.isOpen = true
      this.$emit('input', true)
    },

    close () {
      this.isOpen = false
      this.$emit('input', false)
    },

    onBackdropClick () {
      if (this.closeOnBackdrop) {
        this.close()
      } else {
        this.scale = true
      }
    },

    onScaleEnd () {
      this.scale = false
    },
  },
}
